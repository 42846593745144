<template>
  <div class="list-bar-wrap">

    <div class="header-wrap header-wrap2" ref="HeaderWrap" :style=" 'top:' + top + 'px' ">

      <no-ssr>
        <!--      <template v-if="isLogin && isHasUserCoupon && isShow">
                <div
                  class="new-user-coupon-bar _pointer"
                  @click="navigateTo"
                ></div>

                <div class="inner_center"  @click="navigateTo">
                  <i class="iconfont icon-cha" @click.stop="closeBar"/>
                </div>

              </template>-->

        <!-- 企业案例征集bar -->
        <div class="case-collection-bar" v-if="companyVipState == 1 && isShowCaseCollectionBar"
          @click="navigateToCaseCollection">
          <div class="inner_center">
            <i class="iconfont icon-cha" @click.stop="closeCaseCollectionBar" />
          </div>
        </div>


        <a class="bar-venue-bar" v-if=" isShowVenueBar" @click="navigateToVenue">
          <div class="inner_center">
            <i class="iconfont icon-cha" @click.stop="closeVenueBar" />
          </div>
        </a>


        <!--      <template v-if="isShowVenueBar">
                <a class="bar-venue-bar" v-else @click="navigateToVenue">
                  <div class="inner_center"  >
                    <i class="iconfont icon-cha" @click.stop="closeVenueBar"/>
                  </div>
                </a>
              </template>-->


      </no-ssr>
    </div>

    <div class="header-wrap-placeholder" :style=" 'height: '+ headerHeight + 'px'" />

  </div>
</template>

<script>
import {fnBrowser, fnGuideDownloadCount, fnLocalStorage, fnLogin, fnRouter, fnUser} from "@/fn";
import {
  MODEL_LIST_BAR,
  MODEL_TUPIAN_CASE_COLLECTION_BAR,
  MODEL_TUPIAN_NEW_USER_COUPON_BAR,
  MODEL_TUPIAN_NEW_USER_COUPON_TOP, MODEL_TUPIAN_VENUE_BAR
} from "@/constant/MODELS";
import {MEMBER_LICENSE_VIP, PAY_BUSINESS} from "@/constant/PATH";
import {tag} from "@/utils/statUtil";
import {getCookie} from "~/utils/auth";
import {mapState} from "vuex";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {HOME} from "@/constant/HOME";


/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';
import {ACTIVE_GUOQING, VENUE_DEFAULT} from "@/constant/_constant/CON_BASIC";
import {fnBasic} from "@/fn/_fn";
import {ACT, PAY_COMPANY_NEW} from "@/constant/_Public/Path";

export default {
  name: "TopUpListBar",
  data() {
    return {
      isShow: true,
      isShowFooter: false,
      PAY_BUSINESS,
      isShowCaseCollectionBar: true,
      isShowVenueBar: false,
      headerHeight: 0,
      top: 0
    }
  },
  computed: {
    ...mapState('user',
      ['isHasUserCoupon', 'isLogin', 'companyVipState','isSwitchAccountType']
    ),

    ...mapState('base',
      ['isShowCaseCollectionBar', 'isShowVenueBar']
    ),
    isLogin() {
      return this.$store.state.user.isLogin
    }
  },
  watch: {

    headerHeight:{
      handler(n, o) {
        if (n > 0) {
          setTimeout(()=>{
            this.top = 80
          },300)
        } else {
          this.top = 0
        }
      },
    },
    isLogin: {
      handler(n, o) {
        if (n == true) {
          this.checkIsShow();
        } else {
          this.checkIsShow();
        }
      },
      // immediate: true
    },
    isShow(n) {
      if (n) {
        this.updateIsShowListBar(n)
      }
    },
    isShowCaseCollectionBar:{
      handler(n,o){
        if (n){
          this.getBarShowState();
          this.getCaseCollectionBarShowState();
          this.getVenueBarShowState();
          this.listenHeaderWrapHeight()
        }
      }
    },
    isShowVenueBar:{
      handler(n,o){
        if (n){
          this.getBarShowState();
          this.getCaseCollectionBarShowState();
          this.getVenueBarShowState();
          this.listenHeaderWrapHeight()
        }
      }
    },
    isSwitchAccountType: {
      handler(n, o) {
        if (n == true) {
          console.log('NoticeVipUpgrade -- isSwitchAccountType')
          setTimeout(() => {
            this.getBarShowState();
            this.getCaseCollectionBarShowState();
            this.getVenueBarShowState();
            this.listenHeaderWrapHeight()
          }, )
        }
      }
    },
  },
  mounted() {
    this.getBarShowState();
    this.getCaseCollectionBarShowState();
    this.getVenueBarShowState();
    this.listenHeaderWrapHeight()
  },
  methods: {

    listenHeaderWrapHeight() {
      setTimeout(()=>{
        if (this.$refs.HeaderWrap) {
          this.$nextTick(() => {
            this.headerHeight = this.$refs.HeaderWrap.offsetHeight
          })
        }
      },500)
    },


    getBarShowState() {
      let flag = fnLocalStorage.getItemDate(MODEL_TUPIAN_NEW_USER_COUPON_TOP);
      this.isShow = !flag ? true : false;
    },

    getCaseCollectionBarShowState() {
      let flag = fnLocalStorage.getItemDate(MODEL_TUPIAN_CASE_COLLECTION_BAR);

      this.$store.commit('base/SET_IS_SHOW_CASE_COLLECTION_BAR', this.isShowCaseCollectionBar)

      setTimeout(()=>{
        this.isShowCaseCollectionBar = !flag ? true : false;
      },500)

    },
    closeCaseCollectionBar() {
      fnLocalStorage.setItemDate(MODEL_TUPIAN_CASE_COLLECTION_BAR, 1);
      this.$store.commit('base/SET_IS_SHOW_CASE_COLLECTION_BAR', false)
      // this.$emit('closeBar')
      this.isShowCaseCollectionBar = false;
      this.headerHeight = 0

    },



    getVenueBarShowState() {
      let flag = fnLocalStorage.getItemDate(MODEL_TUPIAN_VENUE_BAR);
      this.$store.commit('base/SET_IS_SHOW_LIST_BAR', this.isShowVenueBar)

      setTimeout(()=>{
        this.isShowVenueBar = !flag ? true : false;
      },500)

    },

    closeVenueBar() {
      this.isShowVenueBar = false;
      this.$store.commit('base/SET_IS_SHOW_CASE_COLLECTION_BAR',false)
      fnLocalStorage.setItemDate(MODEL_TUPIAN_VENUE_BAR, 1);
      // this.$emit('closeBar')
      this.headerHeight = 0
    },




    closeBar() {
      fnLocalStorage.setItemDate(MODEL_TUPIAN_NEW_USER_COUPON_TOP, 1);
      this.isShow = false;
      this.$store.commit('base/SET_IS_SHOW_LIST_BAR', false)
      // this.$emit('closeBar')
    },



    navigateToVenue() {
      // let path = 'https://chaopx.com/venue/6.html';
      // let path = "https://www.mockplus.cn/rp-sale-jun?hmsr=cgcy";
      // let path = "https://m.chaopx.com/act-zl";
      // fnCommon.fnBasic.navigationTo(path);

      fnBasic.navigationTo(PAY_COMPANY_NEW)
    },





    navigateTo() {
      fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.HORIZON, HOME.NEW_USER_COUPON_TYPE.POSITION.TO_PAY_PAGE)
      fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS)
    },

    navigateToCaseCollection() {
      // fnRouter.navigationTo(PAGE_PATH.COMPANY.CASE_COLLECTION)
      // let path = CON_COMMON.CON_BASIC.VENUE_DEFAULT + '/3.html'
      // let path = 'https://chaopx.com/venue/6.html';
      // fnCommon.fnBasic.navigationTo(path);

      fnBasic.navigationTo(ACT)
    },
    showLogin() {
      fnLogin.loginIntercept(false)
    },

    checkIsShow() {
      setTimeout(() => {
        let noLoginWhere = !fnLogin.checkLogin()

        let loginWhere = fnLogin.checkLogin() && !fnUser.checkIsVip() && (getCookie('companyVipState') != 1) && !this.getTodayIsShow();

        let loginWhereOk = fnLogin.checkLogin() && fnUser.checkIsVip() && (getCookie('companyVipState') == 1) && !this.getTodayIsShow();


        this.isShow = -1

        if (noLoginWhere) {
          this.isShow = 0
        }

        if (loginWhere) {
          this.isShow = 1
        }
        if (loginWhereOk) {
          this.isShow = -1
        }


        this.updateIsShowListBar(this.isShow)


      }, 500)
    },

    updateIsShowListBar(n) {
      if (n != -1) {
        this.$store.commit('base/SET_IS_SHOW_LIST_BAR', true)
      } else {
        this.$store.commit('base/SET_IS_SHOW_LIST_BAR', false)
      }
    },
    getTodayIsShow() {
      return this.$localStorage.getItemDate(MODEL_LIST_BAR);
    },
    close() {
      this.isShow = -1;
      this.$localStorage.setItemDate(MODEL_LIST_BAR)
    },
    tag(stat) {
      tag(stat)
    },

    navigatorTo(path) {
      fnBrowser.navTo(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-bar-wrap {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  transition: all 0.5s ease;

  .inner_center {
    @include Position(1, 5, 50%, 0);
    transform: translateX(-50%);
    height: 100%;

    .icon-cha {
      width: 24px;
      height: 24px;
      background: rgba(0, 0, 0, 0.2);
      @include Position(1, 2, 0, 50%);
      transform: translateY(-50%);
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      font-size: 14px;
      @include ElementHover('', rgba(0, 0, 0, 0.3))
    }
  }

  .new-user-coupon-bar {
    @include BgImage(100%, 56px, 'tupian/new-user-coupon-bar.jpg?v=2', 1);
    cursor: pointer;
  }

  .case-collection-bar {
    @include BgImage2(100%, 60px, 'act/act-topbar.png', 1);
    background-size: 1920px 60px;
    cursor: pointer;
  }

  .bar-venue-bar {
    @include BgImage(100%, 60px, 'act/guoqing/newYear2.png', 1);
    background-size: 1920px 60px;

    cursor: pointer;
  }

  .new-user-coupon-footer-bar {
    @include BgImage(638px, 156px, 'tupian/new-user-coupon-footer-bar.png');
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .icon-cha {
      width: 24px;
      height: 24px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px 12px 4px 4px;
      @include Position(1, 2, 0, 44px);
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      z-index: 12;
    }
  }

  .list-bar-default {
    @include BgImage(100%, 56px, 'act/top-up/list-bar.jpg', 1);
    cursor: pointer;
  }

  .list-bar-active {
    @include BgImage(100%, 56px, 'act/top-up/list-bar@active.jpg', 1);

    cursor: pointer;
    position: relative;

    .inner_center {
      position: relative;
      height: 100%;
    }

    .icon-cha {
      width: 22px;
      height: 22px;
      background: #FFFFFF;
      border-radius: 20px;
      opacity: 0.6;
      font-size: 12px;
      color: $color-primary;
      @include LayoutFlex(1);
      @include Position(1, 2, 120px, 50%);
      transform: translateY(-50%);
    }

  }
}

.header-wrap {
  //border-bottom: 1px solid #ECEFF6;
  //height: 60px;
  background: #FFFFFF;
  position: fixed;
  //position: relative;
  //top: 276px;
  left: 0;
  right: 0;
  z-index: 99;
  //top: 0;

  .filter-box {
    height: 100%;
    font-size: 16px;
    color: #424866;
    line-height: 22px;
    @include LayoutFlex(1);
    justify-content: flex-start;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;

    @include ElementHover($color-primary, '') {
      .icon-filter {
        color: $color-primary;
      }
    }

    ;

    .icon-filter {
      font-size: 20px;
      margin-right: 7px;
      color: #424866;
    }


  }


  .category-list-wrap {
    position: relative;
  }

}

.header-wrap-placeholder,
{
transition: all 0.2s ease-in-out;
}
</style>
